.tech
{
    color: #ff7050;
    margin: 0 4px 0 4px;
    cursor: pointer;
}

.tech:hover
{
    background: #ff7050;
    color: #fff;
    padding: 0 3px 0px 3px;
    border-radius: 4px;
}