#code
{
    width: 100%;
    height: auto;
    filter: blur(1.7px) brightness(0.8);
}

#container
{
    /* width: 100%;
    float: right;
    margin-top: 3%; */
    /* border-bottom: 1px solid #283652; */
}

.label-btn
{
    border: 0.1px solid #283652;
    border-radius: 10px;
    background: #0f172a;
}

.label-text
{
    text-align: center;
    width: 100%;
    margin-bottom: -5.5%;
}

.font-fam
{
    font-family: Inter,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}