.invalid-email
{
    color:rgb(252,58,90);
    border: 2px solid rgb(252,58,90);
}

.invalid-email:focus
{
    color:rgb(252,58,90);
    border: 2px solid rgb(252,58,90);
}