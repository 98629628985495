.triangle-right 
{
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}


.skillbull .triangle-right
{
    transform: translateY(-100%);
    float: right;
    margin-right: -21px;
}
